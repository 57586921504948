<template>
  <div>
    <div class="ct dd">
      <h2 style="border-bottom: none;">
        <router-link to="/" tag="i">←返回</router-link>
        指纹管理
      </h2>
    </div>
    <div class="ct">
      <ul>
        <li>
          <router-link to="/fingerprint/finger1" tag="div">登记指纹（随意登记）</router-link>
        </li>
        <li>
          <router-link to="/fingerprint/finger2" tag="div">登记指纹（用户号登记）</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <router-link to="/fingerprint/finger3" tag="div">删除指纹（用户号删除）</router-link>
        </li>
        <li>
          <router-link to="/fingerprint/finger4" tag="div">删除指纹（手指删除）</router-link>
        </li>
        <li>
          <router-link to="/fingerprint/finger5" tag="div">删除指纹（全部删除）</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
    name: 'rootPassword',
    data() {
        return {}
    },
    created() {


    },
}
</script>

